import React from "react";
import Page from "../components/Page";

const codeWrap = {display: "block", whiteSpace: "pre-wrap"};
const errorMessage = {color: "primary", component: "span"};

export default function Component() {
  const pageURL = "https://unknownpage.com";

  return (
    <Page title="Page Not Found">
        <code style={codeWrap}>
          {`
          import (
              "fmt"
              "http"
          )
          
          _, err := htt.Get("${pageURL}")
          fmt.Println(err)`}
          </code>
        {/* </Typography> */}

        {/* <code style={codeWrap}> */}
          <div style={errorMessage}>
        {"error: the page you are looking for does not exist" }
        </div>
        {/* </code> */}

      {/* </center> */}
    </Page>
  );
}
